import React, { useState } from "react";
import { Container, Button, Form, Modal } from "react-bootstrap";
import { Wheel } from "react-custom-roulette";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from "react-helmet";

const Randomname = () => {
  const [names, setNames] = useState("");
  const [spinning, setSpinning] = useState(false);
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [showWheel, setShowWheel] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedName, setSelectedName] = useState("");
  const [bgColor, setBgColor] = useState("#1E3C72");
  const [showEdit, setShowEdit] = useState(false);

  const handleCreate = () => {
    if (!names.trim()) {
      alert("Please add names before creating the wheel.");
      return;
    }
    setShowWheel(true);
  };

  const handleStartRoulette = () => {
    let nameArray = names.split("\n").map(name => name.trim()).filter(name => name);
    if (nameArray.length === 0) {
      alert("Please enter at least one name.");
      return;
    }
    const randomIndex = Math.floor(Math.random() * nameArray.length);
    setPrizeNumber(randomIndex);
    setMustSpin(true);
    setSpinning(true);
  };

  const onFinished = () => {
    setSpinning(false);
    setMustSpin(false);
    let nameArray = names.split("\n").map(name => name.trim()).filter(name => name);
    setSelectedName(nameArray[prizeNumber]);
    setShowModal(true);
  };

  const handleRemoveName = () => {
    let nameArray = names.split("\n").map(name => name.trim()).filter(name => name);
    nameArray = nameArray.filter(name => name !== selectedName);
    setNames(nameArray.join("\n"));
    setShowModal(false);
  };

  const handleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  };

  const handleNew = () => {
    setShowWheel(false);
    setNames("");
  };

  const handleEdit = () => {
    setShowEdit(true);
    setShowWheel(false);
  };

  const segments = names
    .split("\n")
    .map(name => name.trim())
    .filter(name => name)
    .map(name => ({ option: name }));

  return (
    <Container fluid className="roulette-container text-center d-flex flex-column align-items-center justify-content-center min-vh-100" style={{ background: bgColor, backgroundSize: "cover", paddingBottom: "50px" }}>
      <Helmet>
        <title>Random Name Picker</title>
      </Helmet>
      <Form.Group className="mb-3">
        <Form.Label>Choose Background Color:</Form.Label>
        <Form.Control type="color" value={bgColor} onChange={(e) => setBgColor(e.target.value)} />
      </Form.Group>
      {!showWheel ? (
        <div className="name-input-container text-center" style={{ width: "50%" }}>
          <h2>Names (one per line):</h2>
          <Form.Control
            as="textarea"
            rows={10}
            value={names}
            onChange={(e) => setNames(e.target.value)}
          />
          <div className="button-group mt-3 d-flex justify-content-center gap-3">
            <Button variant="primary" className="roulette-button" onClick={handleCreate}>
              Create
            </Button>
          </div>
        </div>
      ) : (
        <div className="roulette-content text-center">
          <h2 className="roulette-header">Random Name Picker!</h2>
          <div className="d-flex justify-content-center">
            <Wheel
              mustStartSpinning={mustSpin}
              prizeNumber={prizeNumber}
              data={segments}
              backgroundColors={["#FF5733", "#33FF57", "#3357FF", "#F4C724", "#FF33A8", "#A833FF"]}
              textColors={["white"]}
              onStopSpinning={onFinished}
              outerBorderColor="black"
              outerBorderWidth={5}
              innerBorderColor="white"
              innerBorderWidth={5}
              radiusLineColor="black"
              radiusLineWidth={2}
              fontSize={18}
              textDistance={65}
              perpendicularText={false}
            />
          </div>
          <div className="roulette-controls mt-3 d-flex flex-wrap justify-content-center gap-3">
            <Button variant="primary" className="roulette-button" onClick={handleStartRoulette}>
              Start
            </Button>
            <Button variant="info" className="roulette-button" onClick={handleNew}>New</Button>
            <Button variant="warning" className="roulette-button" onClick={handleEdit}>Edit</Button>
            <Button variant="success" className="roulette-button" onClick={handleFullscreen}>Fullscreen</Button>
          </div>
        </div>
      )}
      
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{selectedName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Congratulations! The selected name is: <strong>{selectedName}</strong></p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleRemoveName}>Remove Name</Button>
          <Button variant="success" onClick={() => setShowModal(false)}>Continue</Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Randomname;
