import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Alert, Card } from 'react-bootstrap';
import { FaYoutube, FaDownload, FaImage } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Helmet } from 'react-helmet';
import jsPDF from 'jspdf';
import './YouTubeThumbnailDownloader.css';

const YouTubeThumbnail = () => {
    const [url, setUrl] = useState('');
    const [thumbnail, setThumbnail] = useState(null);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [size, setSize] = useState('maxresdefault');

    const getThumbnail = () => {
        setIsLoading(true);
        setError('');
        const videoId = extractVideoId(url);
        if (videoId) {
            const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/${size}.jpg`;
            setThumbnail(thumbnailUrl);
        } else {
            setError('Invalid YouTube URL');
        }
        setIsLoading(false);
    };

    const extractVideoId = (url) => {
        const regex = /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/;
        const match = url.match(regex);
        return match ? match[1] : null;
    };

    const downloadAsPDF = () => {
        if (thumbnail) {
            const doc = new jsPDF();
            const img = new Image();
            img.src = thumbnail;
            img.crossOrigin = "anonymous";
            img.onload = () => {
                doc.addImage(img, 'JPEG', 15, 40, 180, 100);
                doc.save('thumbnail.pdf');
            };
        }
    };

    return (
        <Container className="my-5">
            <Helmet>
                <title>YouTube Thumbnail Downloader</title>
            </Helmet>
            <Row className="justify-content-center">
                <Col md={10} lg={8}>
                    <Card className="p-4 shadow bg-light text-dark">
                        <Card.Title className="text-center mb-4">
                            <FaYoutube /> <strong>YouTube Thumbnail Downloader</strong>
                        </Card.Title>
                        <Form>
                            <Form.Group controlId="formUrl">
                                <Form.Label>Enter YouTube Video URL</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={url}
                                    onChange={(e) => setUrl(e.target.value)}
                                    placeholder="https://www.youtube.com/watch?v=abcdefghijk"
                                />
                            </Form.Group>
                            <Form.Group controlId="formSize" className="mt-3">
                                <Form.Label>Select Thumbnail Size</Form.Label>
                                <Form.Control as="select" value={size} onChange={(e) => setSize(e.target.value)}>
                                    <option value="default">Default (Small)</option>
                                    <option value="mqdefault">Medium</option>
                                    <option value="hqdefault">High Quality</option>
                                    <option value="maxresdefault">Full HD</option>
                                </Form.Control>
                            </Form.Group>
                            <Button variant="primary" className="w-100 mt-3" onClick={getThumbnail} disabled={isLoading}>
                                {isLoading ? 'Fetching...' : <FaImage />} Fetch Thumbnail
                            </Button>
                        </Form>
                        {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
                        {thumbnail && (
                            <div className="mt-4 text-center">
                                <img src={thumbnail} alt="YouTube Thumbnail" className="thumbnail-image img-fluid rounded shadow" style={{ maxWidth: '100%', height: 'auto' }} />
                                <div className="mt-3">
                                    <Button variant="success" onClick={downloadAsPDF}>
                                        <FaDownload /> Download as PDF
                                    </Button>
                                </div>
                            </div>
                        )}
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default YouTubeThumbnail;
